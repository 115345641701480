<template>
  <div class="interactive-map">
    <Icon
      :class="continent?.attributes.slug"
      class="map-continent"
      name="continent-map"
      @click="selectContinent"
    />
    <div class="continent-infos">
      <div class="title">{{ continent?.attributes.name ?? defaultTitle }}</div>
      <div class="description">
        {{ continent?.attributes.description ?? defaultDescription }}
      </div>
      <button
        v-if="continent"
        class="more-continent"
        @click="navigateTo(`/${continent?.attributes.slug}`)"
      >
        En savoir plus
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { MvContinent } from '@/lib/strapi-types/MvContinent';

const { continents } = defineProps<{
  continents?: MvContinent[];
  continent?: MvContinent;
}>();
const emit = defineEmits<{ (event: 'update:continentSelected', value: MvContinent): void }>();

const defaultDescription =
  'Survolez la carte et sélectionnez le continent vers lequel vous souhaitez voyager. Nous vous afficherons notre sélection de voyages sur mesure vers ce continent. A vous de trier selon vos envies !';

const defaultTitle = 'Quel sera votre prochain voyage ?';

function selectContinent() {
  const elementsList = document.querySelectorAll(':hover');

  const elementPath = Array.from(elementsList).find(element => element.nodeName === 'path');

  const continentSelected = continents?.find(
    continent => continent.attributes.slug === elementPath?.id
  );

  continentSelected && emit('update:continentSelected', continentSelected);
}
</script>
<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/colors.scss';
@use '$/button.scss';

.interactive-map {
  justify-content: space-between;
  width: metrics.$inner-width;
  margin-bottom: 60px;

  @include breakpoints.mobile() {
    display: none;
  }

  .map-continent {
    &:deep(svg path) {
      &:hover {
        cursor: pointer;
        fill: colors.$primary-yellow;
      }
    }
  }

  @each $continent in asie, amerique-latine, europe, amerique-du-nord, oceanie-et-iles, afrique {
    .#{$continent} {
      &:deep(svg ##{$continent}) {
        fill: colors.$primary-yellow;
      }
    }
  }

  .continent-infos {
    flex-direction: column;
    width: 100%;
    height: 300px;
    padding-left: 40px;

    .description {
      flex: 1;
    }

    .title {
      margin-bottom: 24px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }

    .more-continent {
      @include button.outlined();

      width: fit-content;
      margin-top: 24px;
    }
  }
}
</style>
