<template>
  <div
    class="push-marketing"
    :style="`background-image: url('${img(
      `${strapiURL}${marketingPush.image?.data.attributes.url ?? marketingPush.image_path}`
    )}'); background-position:center`"
  >
    <div class="content">
      <NuxtImg
        v-if="marketingPush?.logo?.data || marketingPush?.logo_path"
        :src="marketingPush?.logo?.data.attributes.url ?? marketingPush.logo_path"
        provider="twicpics"
        :alt="marketingPush?.logo?.data?.attributes?.alternativeText ?? marketingPush?.title"
        ratio="3:1"
        class="partner"
      />

      <div
        class="title"
        :class="{ 'margin-top': !marketingPush.logo?.data || !marketingPush.logo_path }"
      >
        {{ marketingPush.title }}
      </div>
      <NuxtLink v-if="marketingPush?.cta" class="button" :to="marketingPush.cta?.url">
        {{ marketingPush.cta?.label }} <Icon name="arrow-up" />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MarketingPush } from '@/lib/strapi-types/components/MarketingPush';

defineProps<{
  marketingPush: MarketingPush;
}>();

const img = useImage();
const strapiURL = useRuntimeConfig().public.strapi.url;
</script>

<style lang="scss" scoped>
@use '$/fonts.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/breakpoints.scss';

.push-marketing {
  $card-width: 248px;
  $bottom-height: 54px;
  $card-mobile-width: 280px;
  $card-mobile-height: 375px;

  position: relative;

  overflow: hidden;
  flex-direction: column;
  flex-shrink: 0;

  width: $card-width;
  height: 280px;

  color: white;

  background-size: cover;
  border-radius: 8px;

  @include breakpoints.mobile() {
    width: 100%;
    height: $card-mobile-height;
  }

  .content {
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    height: 100%;
    padding: 11px 16px;

    .partner {
      width: 32px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;

      @include breakpoints.mobile() {
        width: 64px;
        margin-top: 80px;
      }
    }

    .title {
      display: flex;
      justify-content: center;

      font-size: 24px;
      font-weight: bold;
      line-height: 28.8px;
      text-align: center;

      @include breakpoints.mobile() {
        line-height: 28.8px;
      }

      &::first-letter {
        font-family: fonts.$marco-vasco;
      }
    }

    .margin-top {
      margin-top: 60px;
    }

    .button {
      @include button.plain(colors.$primary-yellow);

      width: fit-content;
      margin: 10px auto;
      padding: 0 20px;

      @include breakpoints.mobile() {
        margin-top: 72px;
      }

      :deep(.icon) {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
